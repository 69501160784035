.table {
    background: white;
    width: 100%;
    padding: 32px;
    margin: auto;
    position: relative;
}

@media (min-width: 768px) {
    .table {
        border: solid 1px rgba(229, 231, 235, 1);
        border-radius: 24px;
    }
}

/* mobile */
@media (max-width: 480px) {
    .table {
        padding: 0;
    }
}