.home {
    height: 850px;
}

@media (max-width: 950px) {
    .home {
        padding: 25px !important;
        height: auto !important
    }

    .logos > img {
        margin-top: 15px !important;
    }
}

.logos-title {
    font-size: 12px;
    letter-spacing: 2px;
    opacity: 0.4;
}

.title {
    max-width: 500px;
    font-weight: 900;
    text-align: center;
    font-size: 36px;
    line-height: 36px;
    margin: auto;
    opacity: 0.9;
}

.middle-row {
    display: flex;
}

.landing-left {
    flex-grow: 0;
    width: 300px;
    margin-top: 100px;
    margin-left: auto;
    padding-right: 20px;
}

.upload-container {
    flex-grow: 10;
    max-width: 640px;
    margin-left: 80px;
    margin-right: 80px;
}

@media (max-width: 1200px) {
    .upload-container {
        margin: auto !important;
    }
}

.arrow-cta {
    flex-grow: 0;
    width: 300px;
    font-size: 24px;
    font-family: 'Indie Flower', cursive;
    color: #914AB5;
    margin-top: 100px;
    text-align: left;
    margin-right: auto;
}

.landing-testimonial {
    transform: rotate(-4deg);
}

.landing-testimonial > p {
    font-family: 'Indie Flower', cursive;
    letter-spacing: 2px;
    font-size: 16px;
    max-width: 250px;
    margin-left: auto;
    opacity: 0.7;
}

.arrow-cta > div {
    margin-bottom: 12px;
    padding-left: 100px;
}

.counter {
    font-size: 20px;
    opacity: 0.5;
}

.upload-box {
    background: white;
    max-width: 780px;
    margin: auto;
    box-shadow: 0 2px 50px rgb(0 0 0 / 12%);
    padding: 20px;
    border-radius: 20px;
}

.mac-app {
    margin-top: 100px;
}

.mac-app:hover {
    transition: opacity 0.5s;
}

.mac-app-img {
    opacity: 0.3;
    transition: opacity 0.3s;
}

.mac-app-img:hover {
    opacity: 1;
    transition: opacity 0.3s;
}