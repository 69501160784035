.analytics-button {
    border: solid 1px rgba(255, 0, 0, 0);
    background: none;
    height: 30px;
    color: #989898;
    margin-right: 10px;
}

.analytics-button:hover {
    background: #ececec;
}

.domain-button:hover {
    background: #ececec;
}

.domain-button-label {
    display: inline-block;
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.domain-button-label.trial {
    max-width: 170px;
}

.domain-button:hover > button {
    background: #ececec !important;
}

.domain-button > button:active, .domain-button > button:focus, .domain-button > button:visited  {
    background: none;
}

.sites-table > tr > * .domain-button > button {
    border-color: transparent;
}

.sites-table > tr > * .domain-button > .dropdown-toggle {
}

.sites-table > tr:hover > * .domain-button > button {
    border: solid 1px #d9d9d9;
}

.sites-table > tr:hover > * .domain-button > .dropdown-toggle {
    visibility: visible;
}

.open-icon {
    visibility: hidden;
    opacity: 0.5;
}

.sites-table > tr:hover > * .domain-button > .open-icon {
    visibility: visible;
}

.analytics-button {
    border: solid 1px #d9d9d9;
    border-radius: 5px;
}

.analytics-button-label {
    display: none;
}

.sites-table > tr:hover > * .analytics-button-label {
    display: inline-block;
}

.actions-col {
    display: flex;
    height: 72px;
    align-items: center;
}

.scroll-container {
    overflow: hidden;
}

.mobile-table {
    display: none;
}

@media (max-width: 767px) {
    .mobile-table {
        display: block;
    }

    .desktop-table {
        display: none;
    }
}

.domain-mobile {
    display: flex;
    flex-direction: column;
    padding: 0 5px;
}

.modified {
    font-size: 0.7rem;
}

.column1 {
    width: 60%;
    padding-left: 15px;
}

.column2 {
    width: 40%;
}

.actions-col-mobile {
    display: flex;
    align-items: center;
    margin-top: 15px;
}

.row-content  {
    display: flex;
    align-items: baseline;
}