.container-pricing {
    max-width: 1100px;
    margin: 55px auto 0 auto;
    display: flex;
    align-items: center;
}

.pricing-h1 {
    background: linear-gradient(90deg, #AE5DD7 60%, #0D6EFD 90%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent
}

.pricing-desc {
    text-align: left;
    max-width: 80%;
}

@media (max-width: 750px) {
    .container-pricing {
        margin-top: 10px;
        padding: 5%
    }
    .pricing-desc {
        margin-top: 20px;
        max-width: 100%;
    }
}
