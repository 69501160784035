.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 12px 0 12px;
}

.odd {
  background: #f1f1f1;
}

.list {
  width: 100%;
  max-height: 150px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.label {
  max-width: 100px;
  word-break: break-all;
}

.trialCTA {
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #fbf3ff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

@media (max-width: 750px) {
  .trialCTA {
    display: block;
    text-align: center;
  }
}