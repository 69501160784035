.container {
    display: flex;
    border: none;
    color: #007bff;
    font-size: 12px;
    background: none;
    height: 28px;
    border-radius: 8px;
    align-items: center;
}

.container:hover {
    background-color: #eaeaea;
}