.border-button-b {
    font-size: 14px;
    border: solid 2px #bc67ec;
    color: #bc67ec;
    border-radius: 5px;
    background: none;
    padding: 6px 14px 6px 14px;
}

.border-button-b:hover {
    border: solid 2px #6e2f9a;
    color: #6e2f9a;
    background: rgba(255, 255, 255, 0.19);
}
