.container {
    max-width: 1100px;
    margin: 120px auto 0 auto;
}

.h2 {
    font-weight: 900;
    font-size: 36px;
}

.h3 {
    font-size: 64px;
    font-weight: 800;
    line-height: 64px;
    background: linear-gradient(91deg, #AE5DD7 0%, #0D6EFD 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.ctaBox {
    text-align: center;
    border-radius: 40px;
    padding: 80px;
    background: radial-gradient(453.32% 127.15% at 21.47% 100%, rgba(13, 110, 253, 0.16) 0%, rgba(174, 93, 215, 0.24) 100%), #FFF;
    margin: 72px 0;
}

.ctaButton {
    padding: 16px 36px !important;
    background: #000;
    border-radius: 32px !important;
    border: none;
    color: #ffffff;
    font-size: 18px;
    font-weight: 500;
    margin-top: 40px;
}

@media (max-width: 950px) {
    .container {
        margin-top: 90px !important;
        display: block !important;
        padding: 25px;
    }

    .h3 {
        font-size: 36px;
        line-height: 36px;
    }

    .ctaBox {
        padding: 40px;
        margin: 36px 0;
    }

    .faqList {
        margin-top: 80px;
    }
}

.faqList {
    max-width: 800px;
    margin: auto;
}

.accordion {
    margin-top: 50px;
}