.book-call-modal .modal-content {
    border-radius: 12px;
    border: none;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  }
  
  .book-call-modal .modal-header {
    border-bottom: 1px solid #f5f5f5;
    padding: 20px 24px;
  }
  
  .book-call-modal .modal-body {
    padding: 24px;
  }
  
  .book-call-modal .modal-title {
    font-weight: 600;
    font-size: 18px;
  }
  
  .profile-image-container {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }
  
  .profile-image {
    width: 125px;
    height: 125px;
    border: 8px solid #dadada;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .book-call-btn {
    background-color: #007bff; 
    border: none;
    padding: 12px 24px;
    border-radius: 8px;
    font-weight: 500;
    transition: all 0.2s ease;
    color: white;
  }
  
  .book-call-btn:hover {
    background-color: #0069d9; 
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }