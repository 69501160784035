.linkButton {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 12px;
}

.linkButton:hover {
    background: #eaeaea !important;
}

.buttonIcon {
    height: 20px;
    margin-right: 5px;
}

.buttonText {
    font-size: 14px;
}

.linkButton:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}