.menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 8px;
    border: 1px solid #E5E7EB;
    background: #FFF;
}

.menuItem {
    padding: 0 7px 0 7px;
    display: inline-block;
    cursor: pointer;
    border-radius: 5px;
}

.contentContainer {
    display: flex;
    padding: 8px 0;
    flex-direction: column;
    align-items: center;
    align-self: stretch; 
    border-bottom: 1px solid #E5E7EB;
}

.contentContainer:last-child {
    border-bottom: none;
}

.contentBox {
    display: flex;
    padding: 8px 12px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    cursor: pointer;

    &:hover {
        background-color: #EAEAEA;
    }
}

.disabled {
    opacity: 0.5;
}

.img {
    width: 16px;
    height: 16px
}

.contentText {
    color: #1F2937;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal; 
    white-space: nowrap;
}

@media (max-width: 768px) {
    .menu {
        border: 0;
    }
}