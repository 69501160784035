.page {
    padding: 24px;
}

.header {
    border: none;
    padding: 24px;
}

.header span {
    font-size: 1.2em;
    font-weight: 600;
}

.modalBody {
    padding: 0 24px;
}

.progress {
    width: 100%;
    margin-right: 20px;
}

.content {
    padding-top: 0;
}


.steps {
    display: flex;
    gap: 4px;
}

.step {
    width: 100%;
    height: 8px;
    background: grey;
    border-radius: 20px;
    /* min-width: 80px; */
    opacity: 0.1;
}
.completeStep  {
    background: #838383;
    opacity: 1;
}

.btnContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 24px 0;
}

.backButton {
    background: transparent;
    color: black;
    cursor: pointer;
}


.backButton:disabled {
    background-color: transparent;
    color: black;
    cursor: disabled;
}

.backButton:hover {
    background: transparent;
    color: black;
    border: none;
}

.backArrow {
    color: black !important;
    margin-right: 5px;
}

.forwardButton {
    background: #0d6efd !important;
}

.forwardButton:disabled {
    color: white;
    background: grey;
}

.forwardButton:hover {
    background: #0b5ed7 !important;
}

.right {
    justify-content: flex-end;
}

.center {
    justify-content: center;
}

.retryEntri {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 5px 0;
    justify-content: flex-start;
}

.left {
    justify-content: flex-start;
}

.activeStep {
    background: #ab65d0;
    opacity: 1;
}

.optionCon {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 8px;
    margin-top: 20px;
}


.optionButton {
    padding: 30px 15px;
    border: 0.3px solid #6c757d;
    border-radius: 0.375rem;
    flex-grow: 1 !important;
    width: 100%;
    cursor: pointer;
    text-align: center;
}

.activeOption {
    background-color: #0d6efd;
    border: 0.3px solid #0d6efd;
    color: white !important;

}

.optionButtonContent {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;

    .option {
        font-size: 1.2rem;
        font-weight: bold;
    }

    .optionSubText {
        font-size: 0.7rem;
    }
}


.container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.subHeading {
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.title {
    font-weight: bold;
    font-size: 18px;
}

.subTitle {
    font-size: 16px;
    color: grey;
}

.address {
    display: flex;
    margin-top: 12px;
    flex-direction: column;
    gap: 5px;

    .addressLabel {
        font-size: 0.7rem;
    }

    .domainBox {
        display: flex;
        gap: 5px;
        align-items: center;
    }

    .addressInput {
        display: block;
        width: 100%;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border-radius: 0.375rem;
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    }
}

.warningBox {
    display: flex;
    flex-direction: column;
    text-align: left;
    background: bisque;
    border-radius: 20px;
    padding: 20px;
    gap: 10px;

    .warningHeading {

        display: flex;
        align-items: center;
        gap: 10px;

        span {
            font-size: 1.2rem;
            color: rgb(99, 55, 0)
        }
    }

    .text {
        font-size: 0.9em;
        color: rgb(173, 144, 106);
        text-align: left;

        span {
            font-weight: bold;
        }
    }
}

.recordsCon {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;
    margin-top: 20px;
    max-width: 100%;
}

.table {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #e0e0e0;
    gap: 5px;
}

.record {
    display: flex;
    gap: 12px;
    width: 100%;
    position: relative;
}


.dnsLabel {
    min-width: 80px;
    color: #939292;
    font-size: 0.8em;

}

.dnsValue {
    word-wrap: break-word;
    overflow: hidden;
    font-size: 0.8em;
}

.test > a {
    font-size: 0.8em;
    text-decoration: none; 
}


.record:hover .copy {
    display: block;
}

.copy {
    display: none;
    position: absolute;
    right: -4%;
    top: 65%;
    transform: translateY(-50%);
    cursor: pointer;
}



.checkbox {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-top: 15px;
}

.checkbox input[type="checkbox" i]:checked {
    background-color: #ab65d0 !important;
}

.goDaddyInput {
    margin: 15px 0;
    display: flex;

    .input {
        border: 1px solid grey;
        border-radius: 12px 0 0 12px;
        padding: 8px 12px;
        outline: none;
        width: 100%;
    }

    .domainSearch {
        background: #000 !important;
        color: white;
        border-radius: 0 12px 12px 0;
        white-space: nowrap;
        opacity: 1;
    }
}

.validation-error {
    text-align: left;
    color: red;
    padding: 5px;
    font-size: 14px;
}

.domainSearchCon {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 15px 0;
    overflow: scroll;
    height: 220px;
    box-shadow: 0 2px 50px rgb(0 0 0 / 12%);
    padding: 10px;
    border-radius: 12px;
}

.domainSearch {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;

    .subTitle {
        font-size: 0.9em;
        color: black;
        min-width: 50px;
    }

}

.selectedDomain {
   display: flex;
   flex-direction: column;
   gap: 5px ;
}

.btn {
    font-size: 0.9em; 
    min-width: 50px;
}

.btnCon {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 50px;
}

.unavailable {
    color: grey;
    font-size: 0.9em; 
    font-style: italic;
}