.wrapperOuter {
    overflow: hidden;
}

.wrapper {
    margin: 120px 0 40px 10%;
}

@media (max-width: 950px) {
    .wrapper {
        margin: 20px 0 0 ;
    }
}

.page {
    display: grid;
    grid-template-columns: 1fr 3fr;
    padding: 24px;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    gap: 36px
}

.arrow {
    color: white;
    margin-left: 5px;
}

.container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 12px;
}

.pictures {
    display: flex;
    width: 100%;
    align-items: center;
}

.subtext{
  font-size: 11px;
  font-weight: 500;
  color: #374151;
  text-align: left;
  margin-left: 18px;
}

.content {
    display: flex;
    text-align: left;
    flex-direction: column;
    gap: 32px;
    width: 100%;
    height: 100%;
    align-items: baseline;
    justify-content: center;
}

.title {
    font-size: 48px;
    line-height: 42px;
    font-weight: 900;
    color: #1F2937;
    text-align: left;
}

.contentText {
    display: flex;
    flex-direction: column;
    gap: 24px;
    min-width: 280px;
}

.subtitle {
    font-size: 18px;
    line-height: 24px;
    color: #374151;
    text-align: left;
}

.button {
    text-align: center;
    margin-top: 8px;
    max-width: 210px;
}

.cardContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 12px;
    width: 1200px;
}

@media (max-width: 950px) {
    .cardContainer {
        width: 800px;
    }
}

.reviewCard {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    border-radius: 32px;
    margin-bottom: 10px;
    background: #f5f5f5;
    max-width: 300px;
}

.imgWH {
    width: 48px;
    height: 48px;
}

.img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: #e5e7eb;
    border: solid 3px white;
}

.reviewCardImg {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.profiles {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
}

.names {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.userName {
    font-size: 14px;
    font-weight: 600;
    color: #1F2937;
    margin-bottom: 0;
}

.companyName {
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    color: #374151;
    text-wrap: nowrap;
}

.review {
    font-size: 16px;
    line-height: 24px;
    color: #1F2937;
    text-align: left;
}

.overlapContainer {
    display: flex;
    align-items: center;
}

.roundedImage {
    width: 40px;
    height: 40px;
    margin-right: -10px; /* Adjust this value based on your design */
    z-index: 1;
}

@media (max-width: 950px) {
    .container {
        grid-template-columns: 1fr;
    }

    .page {
        grid-template-columns: 1fr;
    }
}