.button {
    display: flex;
    align-items: center;
    gap: 8px;
}

.small {
    padding: 8px 16px !important;
    font-size: 14px;
}

.large {
    padding: 16px 32px !important;
    font-size: 18px;
}

.secondary-round {
    background: black !important;
    border-radius: 32px !important;
    color: white !important;
    border: none;
}

.secondary-round:hover {
    background: #484848 !important;
}

.primary-gradient-round {
    border: none;
    border-radius: 32px !important;
    background: linear-gradient(90deg, #ae5dd7 0%, #0d6efd 100%) !important;
    color: white;
}

.primary-gradient-round:hover {
    background: linear-gradient(90deg, #8b40b1 0%, #0b58d2 100%) !important;
}

