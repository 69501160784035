.headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px 30px 20px;
}

.leftSection {
    display: flex;
    align-items: center;
}

.title {
    font-size: 24px;
    margin: 0;
    margin-right: 16px;
    text-align: left;
}

.badge {
    background-color: #eedef7;
    padding: 8px 12px;
    border-radius: 12px;
    font-size: 12px;
}

.rightSection {
    display: flex;
    gap: 8px;
}

.button {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    background-color: #007bff;
    color: white;
    box-shadow: lch(0 0 0 / 0.02) 0px 4px 4px -1px, lch(0 0 0 / 0.06) 0px 1px 1px 0px;
}

.button:hover {
    background-color: #0056b3;
}

.buttonIcon {
    width: 24px;
    height: 24px;
    margin-right: 4px;
}


@media (max-width: 768px) {
    .leftSection {
        align-items: flex-start;
        flex-direction: column;
        gap: 8px;
    }
}

/* Mobile styles */
@media (max-width: 480px) {
    .headerContainer {
        padding: 30px 10px;
        align-items: center;
    }

    .title {
        font-size: 20px;
        margin-right: 0;
    }

    .badge {
        padding: 6px 10px;
        font-size: 10px;
        border-radius: 8px;
    }

    .rightSection {
        flex-direction: column;
        align-items: flex-end;
    }

    .btn-group {
        border-radius: 12px;
    }

    .button {
        justify-content: center;
        margin-top: 8px;
    }

    .buttonIcon {
        width: 16px;
        height: 16px;
    }
}