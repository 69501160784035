.page {
  display: grid;
  grid-template-columns: 1fr 2fr;
  width: 70%;
  margin: 50px auto;
  padding: 25px;
  gap: 80px;
}

.tabsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 36px;
  width: 100%;
}

.textHeaderCtn {
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
}

.gradientTextContainer {
  text-align: left;
  font-size: 64px;
  font-style: normal;
  font-weight: 900;
  line-height: 64px;
  max-width: 400px;
  position: relative;
  background: linear-gradient(97deg, #ae5dd7 0%, #0d6efd 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.textWithImage {
  position: relative;
  margin: 0 0.5rem;
}

.gradientText {
  display: inline-flex;
  position: relative;
  background: linear-gradient(99deg, #ae5dd7 -0.42%, #0d6efd 116.58%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradientText::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 16px;
  background: url('/public/assets/img/black_stroke.png') no-repeat;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  height: 100%;
  background: #ffffff;
  box-shadow: 0 2px 50px rgb(0 0 0 / 30%);
  text-align: left;
}

.contentInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 60%;
  padding: 48px;
}

.title {
  color: #111827;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px;
}

.subTitle {
  color: #585858;
  font-size: 22px;
  font-style: normal;
  line-height: normal;
  margin-top: 40px;
}

.quote {
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 8px;
  margin-left: auto;
  text-align: right;
}

.quoteText {
  color: #111827;
  font-size: 20px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
}

.quoteAuthor {
  color: #374151;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.button {
  display: flex;
  padding: 12px 20px !important;
  justify-content: center;
  align-items: center;
  background: #000;
  border-radius: 32px !important;
  color: #ffffff;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  border: none;
  margin-top: 40px;
  max-width: 200px;
}

.tabs {
  margin-left: -10px;
}

.link {
  text-decoration: none;
  color: #fff;
}

.contentImage {
  width: 40%;
  height: 100%;
}

.image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.highlightedText {
  color: #0d6efd;
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .page {
    width: 90% !important;
    margin: 30px auto;
  }

  .tabsContainer {
    padding: 0;
  }
}

@media (max-width: 1023px) {
  .page {
    grid-template-columns: 1fr;
    width: 100%;
    padding: 25px;
  }

  .content {
    display: none;
  }
}
