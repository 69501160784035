.pro-box {
    background: white;
    border-radius: 8px;
    width: 280px;
    padding: 10px;
    border: solid 1px #f1f1f1;
    align-self: start;
}

.pro-box:hover {
    cursor: pointer;
}

.pro-box > h3 {
    margin-top: 8px;
    color: rgba(0, 0, 0, 0.7);
}

.price {
    font-weight: bold;
    font-size: 22px;
    margin: 15px 0 15px 0;
}

.price-period {
    font-weight: normal;
    font-size: 14px;
}

.plan-feature {
    display: flex;
    text-align: left;
    padding: 10px;
    font-size: 14px;
}

.plan-feature > img {
    margin-right: 10px;
}

.most-popular {
    background: #a657d3;
    padding: 5px;
    color: white;
    position: absolute;
    top: -20px;
    left: 90px;
    border-radius: 4px;
}
