.page {
  display: grid;
  grid-template-columns: 1fr 2fr;
  width: 70%;
  margin: 50px auto;
  padding: 25px;
  gap: 80px;
}

.tabsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 36px;
  width: 100%;
}

.textHeaderCtn {
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
}

.period {
  font-weight: normal;
  font-size: 16px;
  margin-top: -7px;
}

.content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  height: 100%;
  background: #ffffff;
  box-shadow: 0 2px 50px rgb(0 0 0 / 10%);
  text-align: left;
}

.contentInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 48px;
}

.title {
  color: black;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px;
}

.subTitle {
  color: #585858;
  font-size: 22px;
  font-style: normal;
  line-height: normal;
  margin-top: 40px;
}

.link {
  text-decoration: none;
  color: #fff;
}

.contentImage::before {
  background: url(assets/icons/thunderbolt.svg) no-repeat;
  background-size: 420px;
  background-position: -120px;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.1;
}

.contentImage {
  background: linear-gradient(90deg, #ae5dd7, #0d6efd) !important;
  position: relative;
  width: 400px;
  height: 535px;
}

@media (max-width: 560px) {
  .contentImage {
    display: none;
  }
}
