body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

/* override react bootstrap styles here*/
.modal-content {
  border-radius: 1.5rem !important;
}

.rounded-2{
  border-radius: 1.5rem !important;
}

/* uniform button styles */
.btn {
  padding: 8px 12px;
  border-radius: 12px;
}