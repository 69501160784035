.template-selector-container {
    display: flex;
    column-gap: 10px;
    margin: auto;
    overflow: scroll;
}

@media (max-width: 768px) {
    .template-selector-container {
        max-width: 80vw;
    }
}

.template-selector-option:hover {
    background: #f1f1f1;
}

.template-selector-option-img {
    border: solid 1px #ececec;
    border-radius: 8px;
    object-fit: cover;
}