.container {
    display: flex;
    align-items: baseline;
    gap: 8px;
  }
  
  .statusBadge {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 4px 8px;
    border-radius: 40px;
    font-size: 12px;
  }
  
  .statusText {
    white-space: nowrap;
    font-size: 10px;
    text-transform: capitalize
  }
  
  .green {
    background-color: #e6f4ea;
    color: rgba(4, 120, 87, 1);
  }
  
  .red {
    background-color: #fce8e6;
    color: #d93025;
  }
  
  .grey {
    background-color: #f1f3f4;
    color: #5f6368;
  }

  .orange {
    background-color: #fef7e0;
    color: #f9ab00;
  }
  
  .blue {
    background-color: #e8f0fe;
    color: #1a73e8;
  }

  .lockIcon {
    /* margin-left: 4px; */
  }

@media (max-width: 480px) {
  .container {
    gap: 0;
  }

  .container img {
    height: 12px;
  }

  .statusText {
    display: none;
  }
  .green {
    background-color: transparent;
  }

  .red {
    background-color: transparent;
  }

  .grey {
    background-color: transparent;
  }

  .orange {
    background-color: transparent;
  }

  .blue {
    background-color: transparent;
  }

}