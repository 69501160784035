.success-box {
    text-align: center;
    padding: 10px;
}

.container-lock-site {
    margin: 50px 0 0 0;
    padding: 24px;
    border-radius: 16px;
    background: #fcfcfc;
    border: solid 1px #e7e7e7;
}

.celebrate {
    height: 130px;
    padding: 20px;
}

.note {
    font-size: 12px;
    color: #aba2a2;
    margin-bottom: 25px;
}

.domain {
    overflow-wrap: break-word;
    font-size: 28px;
    margin-left: 12px;
    margin-right: 12px;
}

.exit {
    cursor: pointer;
    float: left;
    height: 30px;
    padding: 8px;
}

.success-accordion {
    margin-left: 36px;
    margin-right: 36px;
}

.grey-background {
    background-color: #f6f6f6;
    width: 100%;
}

.share-background {
    background-color: #F9F9F9;
    width: 100%;
}

.custom-gap {
    gap: 25px;
}

.share-text{
    color: #463C3B;
    font-weight: 700;
    font-size: 21px;
    margin-top: 2px;
}

.custom-button {
    border: 1px solid gray;
    background-color: white;
    border-radius: 0.375rem;
  }
.card {
    border-top: none !important;
    border-right: none;
    border-left: none;
}

.card-body {
    padding: 0;
}

.accordion-title {
    cursor: pointer;
    border-top: #dddddd solid 1px !important;
    border-bottom: #dddddd solid 1px !important;
    padding-bottom: 12px;
    padding-top: 12px;
}

.accordion-content {
    padding-left: 1.4rem;
    padding-right: 1.4rem;
}

@media (max-width: 768px) {
    .share-background {
        background-color: #f5f5f5;
        width: 100%;
        margin-top: 38px;
    }

    .share-text {
        color: #463C3B;
        margin-top: 18px;
    }

    .custom-button {
        border: 1px solid gray;
        background-color: white;
        border-radius: 0.375rem;
    }
}

@media (max-width: 768px) {
    .share-background {
       height: 16vh;
       margin-top: 10px;
    }

    .share-text{
        margin-top: 5px;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .share-background {
        height: 16vh;
        margin-top: 10px;
    }

    .share-text {
        margin-top: 9px;
    }
}

