.body {
    position: relative;
    min-height: 750px;
    padding: 0 !important;
}

.header {
    display: flex !important;
    justify-content: space-between;
    width: 100%;
    margin-right: 10px;
}

.iframe {
    width: 100%;
    height: 100%;
    border: none;
    margin: 0;
    padding: 0;
    z-index: 10;
    overflow: scroll;
    min-height: inherit;
}

.container {
    width: 100%;
    background: rgba(17, 24, 39, 0.8);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    backdrop-filter: blur(16px);
    border-radius: 0 0 1.5rem 1.5rem;
}

.open {
    display: flex;
    width: 100%;
    flex-direction: column;
    color: #fff;
    align-items: center;
    padding: 0 40px 20px 40px;
    gap: 30px;
    overflow: hidden;
}

.closed {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 48px;
    color: #fff;
}

.dropdown {
    margin-top: 12px;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    background: rgba(227, 227, 227, 0.2);
    padding: 2px 4px;
    border-radius: 10px;
}

.dropdown:hover {
    background: rgba(163, 163, 163, 0.2);
}

.content {
    max-width: 620px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 30px;
    transition: height 0.3s ease;
}

.contentCollapsed {
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: height 0.3s ease;
    margin-bottom: 32px;
    flex: 1;
}

.timer {
    font-size: 0.9em;
    color: lightgray;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    flex: 1;
}

.heading {
    font-size: 3em;
    font-weight: bold;
    line-height: 0.8em;
}

.heading2 {
    font-size: 2em;
    font-weight: bold;
    flex: 1;
}

.subHeading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
}

.subTitle {
    font-size: 1.5em;
    word-spacing: 1.5px;
}

.info {
    color: lightgray !important;
    font-size: 14px;
}

.description {
    color: #efefef !important;
    font-size: 16px;
    margin-bottom: 5px;
    max-width: 400px;
}

.btnCon {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    flex: 1;
}

.button1 {
    border-radius: 30px;
    background: #fff;
    color: #000;
    font-size: 14px;
    padding: 12px 24px;
    border: none;
    transition: all 0.3s ease-in-out;
    stroke: #FFF;
    cursor: pointer;
}

.button1:hover {
    background: #ffffffb8;
    color: #000;
}

.ctaBtn {
    border-radius: 30px;
    color: #fff;
    font-size: 18px !important;
    border: none;
    transition: all 0.3s ease-in-out;
    stroke: #FFF;
    cursor: pointer;
    padding: 16px 32px !important;
}

.closed .subTitle {
    font-size: 18px !important;
}

/* media queries */
@media (max-width: 1200px) {
    .contentCollapsed {
        flex-direction: column;
        align-items: center;
    }
    .open {
        gap: 15px;
        padding: 0 12px;
    }

    .open .content {
        gap: 8px;
        margin-bottom: 15px;
    }

    .open .heading {
        font-size: 2em;
    }

    .open .timer {
        padding: 8px;
        font-size: 0.5em;
    }

    .open .subHeading {
        padding: 8px;
        font-size: 0.7em;
    }

    .open .description {
        font-size: 0.9em;
    }

    .open .button1 {
        font-size: 10px;
        line-height: 18px;
    }

    .open .ctaBtn {
        line-height: 18px;
    }

    .closed {
        padding: 0;
    }

    .closed .content2 {
        flex-direction: column;
        margin: 0;
        align-items: center;
    }

    .closed .heading2 {
        text-align: center;
        margin-top: 10px;
    }

    .closed .timer {
        padding: 8px;
        font-size: 0.7em;
    }

    .closed .btnCon {
        margin: 10px 0;
    }

    .closed .button1 {
        font-size: 10px;
        line-height: 18px;
    }

    .header .ctaBtn {
        line-height: 18px;
        padding: 8px 16px
    }
}