.tooltip {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.link {
    text-decoration: underline;
    color: #fff;
    font-size: 10px;
    font-style: italic;
    cursor: pointer;

}

.underline-on-hover:hover {
    text-decoration: underline;
  }