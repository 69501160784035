:global(:root) {
    --bs-dropdown-spacer: 16px !important;
}

.img {
    width: 18px;
    height: 18px;
}

.contentText {
    color: #1F2937;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal; 
    white-space: nowrap;
}

.contentBox {
    display: flex;
    padding: 8px 16px;
    gap: 12px;
    align-self: stretch;
    cursor: pointer;
    text-decoration: none;
    align-items: center;
    border-radius: 8px;

    &:hover {
        background-color: #F3F4F6;
    }
}

.contentBoxNav {
    display: flex;
    padding: 8px 16px;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        background-color: #F3F4F6;
        border-radius: 12px;
    }
}

.menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 12px;
    background: #FFF;
    width: 100%;
}

.contentContainer {
    display: block;
    padding: 8px;
    flex-direction: column;
    align-items: center;
    align-self: stretch; 
    border-bottom: 1px solid #E5E7EB;
    width: 100%;
}

.contentContainer:last-child {
    border-bottom: none;
}

.accountBtn {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #374151 !important;
    border: 1px solid #E5E7EB;
    border-radius: 12px;
    box-sizing: border-box;
    box-shadow: lch(0 0 0 / 0.02) 0px 4px 4px -1px, lch(0 0 0 / 0.06) 0px 1px 1px 0px;
}

.accountBtn:hover {
    background: #fafafa;
}

.dropdownToggle::after{
    display: none !important;
}

.img_account{
    margin-left: 10px;
}

.colorText {
    background: linear-gradient(90deg, #AE5DD7 -13.77%, #0D6EFD 100.36%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent
}

@media (min-width: 768px) {
    .twoColumn {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 16px;
    }

    .menu {
        max-width: unset;
    }
}
