.container {
    display: flex;
    align-items: center;
    width: 100%;
}

.link {
    color: #0D6EFD;
    text-decoration: underline;
    cursor: pointer;
    padding: 0.375rem 0.75rem;
    border: 1px solid transparent;
    max-width: 480px;

}

.text {
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    white-space: nowrap;  
    overflow: hidden;          
    text-overflow: ellipsis;   
    display: block;
    width: 100%;
}

.disabled {
    color: #6c757d;
    cursor: not-allowed;
    text-decoration: none;
}

.copyLinkBtn {
    margin-left: 5px;
    background: none;
    border: 1px solid #d9d9d9;
    padding: 6px 10px 6px 10px;
    border-radius: 0.375rem;
    visibility: hidden;
}

.copyLinkBtn:hover {
    background: #e3e3e3;
}

@media (max-width: 480px) {
    .text {
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;

    }

    .link {
        padding: 0;
        max-width: 200px;
    }

    .link:hover {
        color: #0056b3;
        border: none;
        border-radius: 0;
    }

    .copyLinkBtn {
        visibility: visible;
        border: none;
    }

    .copyLinkBtn img {
        width: 18px;
        height: 18px;
        opacity: 1;
    }
}

@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1023px) {
    .link {
        padding: 0;
        max-width: 320px;
    }
}


@media only screen 
and (min-device-width: 481px) 
and (max-device-width: 767px) {
    .link {
        padding: 0;
        max-width: 230px;
    }
}