.account-tab .dropdown-toggle::after{
    display: none !important;
}

.account-tab .nav-link{
    color: #374151 !important;
}

.account-tab .dropdown-menu{
    border-radius: 12px !important;
}

.account-tab .dropdown-menu[data-bs-popper]{
    left: -19% !important; 
}

.integration-tab .dropdown-toggle::after{
    display: none !important;
}

.integration-tab .nav-link{
    color: #374151 !important;
}

.integration-tab .dropdown-menu{
    border-radius: 12px !important;
}

