.guarantee {
    font-size: 18px;
    max-width: 250px;
    border-radius: 16px;
}

.file-size-slider {
    width: 100%;
}

.percent-off-50-inactive {
    font-size: 11px;
    background: #dfe5fd;
    padding: 5px;
    border-radius: 5px;
}

.yearly-toggle:hover {
    .percent-off-50-inactive{
        background: transparent !important;
        border: 1px solid #dfe5fd !important;
    }
}

.percent-off-50 {
    background: transparent !important;
    border: 1px solid #dfe5fd !important;
    padding: 5px;
    border-radius: 5px;
    font-size: 11px;
}

@media (min-width: 480px) {
    .file-size-slider {
        width: 50%;
    }
}

.flip-in-ver-left {
    -webkit-animation: flip-in-ver-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: flip-in-ver-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes flip-in-ver-left {
    0% {
        -webkit-transform: rotateY(80deg);
        transform: rotateY(80deg);
        opacity: 0;
    }
    100% {
        -webkit-transform: rotateY(0);
        transform: rotateY(0);
        opacity: 1;
    }
}
@keyframes flip-in-ver-left {
    0% {
        -webkit-transform: rotateY(80deg);
        transform: rotateY(80deg);
        opacity: 0;
    }
    100% {
        -webkit-transform: rotateY(0);
        transform: rotateY(0);
        opacity: 1;
    }
}

@media (min-width: 950px) {
    .pro-plans-container {
        display:flex
    }
}

.grey-border-button:hover {
    color: #914ab5;
    border-color: #914ab5 !important;
}
.discount {
    background-color: #ebe9e9;
    border-radius: 20px;
    padding: 10px 20px;
}
