.cname-record {
    background: #f5f5f5;
    border-radius: 8px;
}

.flex-records {
    display: flex;
}

.records {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;
    margin-top: 20px;
    max-width: 100%;
}

.records-con {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;
    margin-top: 20px;
    max-width: 100%;
}

.manage-dns-con {
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.add-new-button {
    background-color: transparent !important;
    color: #374151 !important;
    border: 1px solid #E5E7EB !important;
    cursor: pointer;
}

.add-new-button:hover {
    background-color: #F3F4F6 !important;
}

.custom-domain-table-wrapper {
    background: white;
    width: 100%;
    padding: 32px;
    margin: auto;
    position: relative;
}

@media (min-width: 768px) {
    .custom-domain-table-wrapper {
        border: solid 1px rgba(229, 231, 235, 1);
        border-radius: 24px;
    }
}

/* mobile */
@media (max-width: 480px) {
    .custom-domain-table-wrapper {
        padding: 0;
    }
}