.page {
    width: 100%;
    background: #111827;
}

.pageFooter {
    padding-top: 20px;
    padding-bottom: 50px;
    width: 100%;
    background: #111827;
}

.footerContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 24px;
    margin: 72px auto;
    width: 100%;
    max-width: 1300px;
}

.footerInfo {
    display: flex;
    max-width: 300px;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    align-self: stretch;
}

.logo {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: flex-start;
}

.homeHeaderLogo {
    height: 40px;
    text-align: left;
    cursor: pointer;
}

.about {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
    color: #D1D5DB;
}

.madeWith {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    gap: 8px;
}

.socialLinks {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.socialLinks > a {
    color: #D1D5DB;
    text-decoration: none;
}

.linksSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 48px;
    align-self: stretch;
}

.linksCon {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 12px;
    min-width: 166px;
}

.linkHeader {
    font-size: 16px;
    font-weight: bold;
    color: #F3F4F6;
}

.links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    list-style: none;
    text-align: left;
    padding: 0;
}

.links > li {
    cursor: pointer;
    color: #D1D5DB;
    font-size: 14px;
    font-weight: 400;
    padding: 2px 0 2px 0;
}

.links > li > a {
    color: #D1D5DB;
    text-decoration: none;
}

.links > li:hover {
    text-decoration: underline;
}

.footerLinksContainer {
    display: flex;
}

.footerPrivacyContainer {
    width: 100%;
    max-width: 1300px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding-top: 24px;
    border-top: 1px solid #374151;
    padding-left: 20px;
    padding-right: 20px;
}

.privacy {
    font-size: 12px;
    color: #9CA3AF;
    display: flex;
    gap: 24px;
    align-items: flex-start;
}

.privacy > a {
    color: #9CA3AF;
    font-weight: 400;
    text-align: left;
    text-decoration: none;
}

.privacy > a:hover {
    text-decoration: underline;
}

@media (max-width: 1400px) {
    .footerContainer {
        width: 80%;
    }
}

@media (max-width: 750px) {
    .footerContainer {
        flex-direction: column-reverse;
    }

    .linksSection {
        flex-direction: column;
    }

    .linksCon {
        gap: 16px
    }

    .linkHeader {
        margin: 0;
    }

    .footerInfo {
        gap: 20px;
    }

    .footerPrivacyContainer {
        flex-direction: column;
        gap: 10px;
    }
}