.container {
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
    padding: 10px 20px;
    text-decoration: none;
    margin-bottom: 8px;
    border: solid 1px transparent;
}

.container:hover {
    border-radius: 24px;
    border: 1px solid #F6EEFB;
    background: linear-gradient(91deg, rgba(13, 110, 253, 0.01) 0%, rgba(174, 93, 215, 0.06) 100%), #FFF;
    transform: scale(1.05);
}

.container:hover > * .text, .container:hover > .arrow {
    color: black
}

.activeContainer {
    border-radius: 24px;
    border: 1px solid #F6EEFB;
    background: linear-gradient(91deg, rgba(13, 110, 253, 0.01) 0%, rgba(174, 93, 215, 0.06) 100%), #FFF;
}

.activeIcon {
    width: 100%;
    height: 100%;
    filter: invert(32%) sepia(98%) saturate(1636%) hue-rotate(203deg) brightness(97%) contrast(98%);
}

.active {
    color: #374151;
    font-size: 16px;
    font-weight: 700;
}

.imgCon {
    width: 20px;
    height: 20px;
    margin-bottom: 0.4rem !important;
}

.img {
    width: 100%;
    height: 100%;
    filter: grayscale(0%) invert(74%) sepia(0%) saturate(0%) hue-rotate(230deg) brightness(95%) contrast(99%);
}

.container:hover > * .img {
    filter: grayscale(100%);
}

.category {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 22px;
}

.arrowActive {
    color: #0D6EFD;
    font-size: 18px;
}

.arrow {
    color: #E5E7EB;
    font-size: 18px;
}

.text {
    font-size: 16px;
    font-weight: 500;
    color: #9CA3AF;
}

.transition {
    transform: translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    transition-duration: 0.2s;
    transition-property: transform;
}
