.feature {
    margin-top: 15vh;
}

.feature-title-border {
    border-left: solid 2px #bc67ec;
    padding-left: 22px;
}

@media (max-width: 760px) {
    .feature-desc {
        order: 2;
        margin-top: 26px;
    }
}
