.navbar {
    max-width: 1400px;
    border-radius: 16px;
    margin: 16px auto;
    padding: 16px;
    background: transparent;
}

.sticky {
    padding-top: 0 !important;
}

.sticky > .navbar {
    background: rgba(255, 255, 255, 0.7) !important;
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.header-link {
    color: black;
    margin: auto;
}

.header-link:hover {
    color: #6e2f9a !important;
}

.menu-link {
    color: #0D0D0D !important;
    border-bottom: #ffffff00 solid 2px;
    margin-right: 12px;
}

.home-header-logo {
    height: 40px;
    text-align: left;
    cursor: pointer;
}

.home-header-logout {
    height: 20px;
    width: 20px;
    cursor: pointer;
    margin: auto;
}

.account-btn {
    color: #434343;
    background: #f5f5f5;
    border-radius: 8px;
}

.account-btn:hover {
    background: #e5e5e5;
}

.home-header-btn {
    padding: 5px 15px 5px 15px;
    margin: 7px 5px 7px 0;
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 8px;
    color: #434343;
    background: #f6f6f6;
    cursor: pointer;
    border: none;
}

.home-header-btn:hover {
    background: #e5e5e5;
}

.round-white {
    background: rgba(255, 255, 255, 0.09);
    border-radius: 20px;
}

.round-white:hover {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 20px;
}

.container-header {
    width: 250px;
    display: flex;
    position: absolute;
    top: 75px;
    right: 75px;
}

.upload-cta {
    position: absolute;
    top: 66px;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 0 0 8px 8px;
    border-left: solid 1px rgba(67, 67, 67, 0.15)6;
    border-right: solid 1px rgba(67, 67, 67, 0.15);
    border-bottom: solid 1px rgba(67, 67, 67, 0.15);
    z-index: 3;
}

.menu-link:hover {
    border-bottom: #b577d3 solid 2px
}

@media (max-width: 750px) {
    .navbar {
        padding-top: 20px;
        margin: 0;
        border-radius: 0;
    }

    .home-header-logo {
        padding-left: 10px;
        height: 40px;
        margin: auto;
    }

    .upload-cta, .upload-cta-b {
        display: none;
    }
}