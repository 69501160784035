.container {
    max-height: 500px;
    transition: max-height 0.25s ease-in;
}

.even {
    cursor: pointer;
    padding: 3px 16px 3px 16px;
    background: #f3f3f3;
}

.even:hover {
    color: white;
    background: #007bff;
}

.odd {
    cursor: pointer;
    padding: 3px 16px 3px 16px;
    background: #e9e9e9;
}

.odd:hover {
    color: white;
    background: #007bff;
}