.title {
    text-align: left;
    font-size: 64px;
    font-weight: 900;
}

@media (max-width: 750px) {
    .title {
        font-size: 36px
    }
}

.tab {
    border-radius: 18px;
    background: #eeeeee;
    border: 0;
    padding: 8px 18px 8px 18px;
    margin-bottom: 12px;
    margin-right: 8px;
}

.tab:hover {
    background: #e3e3e3;
}

.tabsContainer {
    text-align: left;
    margin-top: 40px;
    margin-bottom: 40px;
}

.activeTab, .activeTab:hover {
    color: white;
    border: none;
    background: #AE5DD7;
}

.whiteFilter {
    filter: brightness(0) invert(1);
}

.example {
    width: 240px;
    margin-left: 48px;
    margin-right: 48px;
}

.exampleDesc {
    height: 100px;
}

.exampleImg {
    width: 260px;
    height: 300px;
    border-radius: 12px;
    object-fit: cover;
    object-position: top;
    border: solid 1px #ececec;
    box-shadow: 0 2px 20px 3px rgb(0 0 0 / 3%);
}