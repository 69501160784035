.HIWContainer {
  display: flex;
  flex-direction: column;
  width: 80%;
  min-height: 690px;
  margin: 72px auto 0 auto;
  background-size: cover;
  background-repeat: no-repeat;
  align-items: center;
  position: relative;
}

.container {
  text-align: left;
  margin: 150px auto 64px auto;
  max-width: 930px;
  display: flex;
}

@media (max-width: 950px) {
  .container {
    margin-top: 50px !important;
    padding: 25px !important;
  }
}

.header {
  display: inline-block;
  font-weight: bold;
  font-size: 40px;
  color: #868686;
}

.subtitle {
  font-family: 'Indie Flower', cursive;
  color: #914ab5;
  font-size: 28px;
}

.steps {
  margin-left: 20px;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  font-size: 22px;
  padding-right: 80px;
}

.steps > div {
  margin-bottom: 26px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.headerContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
}

.headerText {
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  color: #818181;
}

.subheaderText {
  text-align: center;
  font-size: 64px;
  font-style: normal;
  font-weight: 900;
}

.btnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentContainer {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-top: 60px;
  gap: 64px;
}

.line {
  width: 2px;
  height: 100%;
  background: #0d6efd;
  position: absolute;
  left: 24px;
}

.stepsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 30px;
  margin-top: 96px;
  margin-bottom: 56px;
  z-index: 2;
  flex: 1;
}

.box {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
}

.ellipse {
  min-width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: rgba(13, 110, 253, 1);
  box-shadow: 0px 0px 32px rgba(13, 110, 253, 0.32);
}

.span {
  color: white;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  width: 100%;
  /* height: 100%; */
  /* padding: 24px; */
  text-align: left;
}

.stepTitle {
  color: #1f2937;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}

.stepImage {
  background-size: cover; 
  background-repeat: no-repeat;
  height: 100%;
  width: 152px;
}

.stepImage > img {
  width: 100%;
  height: 100%;
  border: none;
}

.stepText {
  color: #1f2937;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.stepVideo {
  width: 100%;
  height: 100%;
  border-radius: 24px;
  border: none;
  padding-top: 40px;
  flex: 1;
}

@media (max-width: 950px) {
  .HIWContainer {
    margin-top: 50px !important;
  }

  .subheaderText {
    text-align: center;
    font-size: 48px;
    font-weight: 900;
    line-height: 48px;
  }

  .stepVideo {
    display: none;
  }

  .stepsContainer {
    max-width: none;
    width: 100%;
  }

  .span {
    font-size: 18px;
    font-weight: 500;
  }
}
