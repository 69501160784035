.file-icon {
    height: 30px;
    margin-right: 10px;
    opacity: 0.8;
}

.zip-file {
    justify-content: center;
    align-items: center;
    display: flex;
}

.file-size {
    margin-top: -8px;
}

.truncate {
    display: inline-block;
    max-width: 325px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.left-align {
    justify-content: left !important;
}

.selected-file-container {
    margin-top: 16px;
    padding: 20px;
    border: solid 1px #eeeeee;
    border-radius: 8px;
    background-color: #fafafa;
}