.h1 {
    font-weight: 900;
    font-size: 64px;
}

.subtitle {
    font-size: 26px;
    color: #676767;
}

@media (max-width: 560px) {
    .h1 {
        font-size: 44px;
    }

    .subtitle {
        font-size: 18px;
    }
}