.containerLogin {
  max-width: 1000px;
  margin: auto;
  display: flex;
  padding-bottom: 3rem;
  padding-top: 3rem;
}

.containerLoginP {
  color: white;
  font-size: 14px;
  text-align: left;
  margin: 20px 0 50px 0;
}

.containerRight {
  flex: 1;
  padding: 20px 0 20px 60px;
}

.containerLeft {
  padding: 20px 60px 20px 0;
  flex: 2;
  border-right: solid rgba(255, 255, 255, 0.7) 1px;
}
.containerLeftB {
  padding: 20px 20px 20px 0;
  flex: 1;
}

@media (min-width: 480px) {
  .containerLeftB {
    padding: 20px 60px 20px 0;
    flex: 1;
    border-right: solid rgba(208, 208, 208, 0.7) 1px;
  }
}

.ul {
  padding-left: 0px !important;
}

.li {
  list-style-type: none;
}

.img {
  text-align: left !important;
  height: 30px;
}

@media (min-width: 950px) {
  .container-cards {
    min-width: 585px;
  }
}

@media (max-width: 750px) {
  .containerLogin {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10%;
  }

  .containerLeft {
    padding: unset;
    border: unset;
  }

  .containerRight {
    border-top: solid 1px rgba(255, 255, 255, 0.32);
    padding: 50px 0 0 0;
    margin-top: 50px;
  }
}

.social-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  transition: all 0.2s ease-in-out;
  border-radius: 10px;
}

.social-button:hover {
  background: #f3f3f3;
}

.social-button img {
  width: 17px;
  height: 17px;
  margin-right: 5px;
}
