.StripeElement {
    margin-top: 12px;
    display: block;
    max-width: 500px;
    padding: 10px 14px;
    font-size: 1em;
    border: 1px solid #ced4da;
    outline: 0;
    border-radius: 4px;
    background: white;
}

.toc {
    opacity: 0.6;
    position: absolute;
    bottom: 16px;
    font-size: 14px;
    right: 100px;
    left: 15px;
    line-height: 1;
    max-width: 300px;
    color: grey;
    padding: 8px;
}

.featureContainer {
    border-radius: 20px 0px 0px 20px !important;
}

.paymentContainer {
    min-height: 500px;
}

.apply{
    text-decoration-color: #0d6efd;
    color: #0d6efd;
    opacity: 0.7;
    line-height: 1;
    cursor: pointer;
}

@media (max-width: 500px) {
    .dialog {
        margin-bottom: 100px;
    }
}

@media (max-width: 768px){
    .featureContainer {
        border-radius: 20px 20px 0px 0px !important;
    }
}