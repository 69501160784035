.page {
    width: 100%;
    padding: 0.8rem;
    background: linear-gradient(90deg, #ae5dd7, #0d6efd);
}

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1024px;
    margin: auto;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
}

.title {
    font-size: 1.2rem;
    font-weight: 700;
    color: white;
    margin-bottom: 4px;
}

.description {
    font-size: 0.9rem;
    color: white
}

.btn {
    cursor: pointer;
    white-space: nowrap;
}

.slide-fwd-center {
    transform: translateY(-100%);
    animation: slideInFromTop 0.3s ease forwards;
}

 @keyframes slideInFromTop {
    0% {
      transform: translateY(-100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
 