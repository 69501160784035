.container {
    display: flex;
    flex-direction: column;
    padding: 24px;
    gap: 18px;
}

.header {
    color: #6e2f9a;
    font-weight: bold;
    font-size: 22px;
    margin-bottom: 0.5rem;
    text-align: left;
}

.subHeader {
    color: grey;
}

.code {
    display: flex;
    flex-direction: column;
}

.copyStatus {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
}

.textarea {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0.5rem;
    resize: none;
    font-size: 0.8rem;
    font-family: monospace;
}

.footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.dimensionBox {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.dimension {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.dimensionInput {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0.5rem;
    font-size: 0.8rem;
    font-family: monospace;
    width: 100%;
    max-width: 60px;
    background: #f3f5f8;
}

.dimensionTitle {
    font-size: 14px;
    font-weight: bold;
    color: #7d7d7d;;
}