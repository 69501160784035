.container {
    padding: 0 20px 10px 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

}

.searchBar {
    display: flex;
    border-bottom: 1px solid #e5e5e5;
    padding: 5px 0;
    width: 50%;
    align-items: center;
}

.input {
    border: none;
    overflow: hidden !important;
    width: 100%;
}

.input:focus-visible {
    outline: none;
}

.icon {
    margin: 0 10px 5px 0;
    opacity: 0.5;
}

.clear {
    opacity: 0.5;
    cursor: pointer;
}

@media (max-width: 480px) {
    .container {
        padding: 0 10px 10px 10px;
    }

    .searchBar {
        width: 100%;
    }

    .input,
    .input::placeholder {
        font-size: 14px;
    }

    .icon {
        height: 20px;
    }
}