.container {
    background-image: url('img/grid_bg.svg');
    background-repeat: no-repeat;
    background-position: center;
    height: 400px;
    background-size: contain;
    padding-top: 75px;
}

@media (max-width: 950px) {
    .container {
        padding-top: 40px !important;
        height: auto !important;
        background-size: auto !important;
    }
    .number {
        font-size: 15vw !important;
    }
}

.number {
    font-size: 130px;
    font-weight: 900;
    line-height: 1;
    background: -webkit-linear-gradient(60deg, rgba(220, 137, 232, 1) 0%, rgba(144, 64, 204, 1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.title {
    font-size: 32px;
}

.subtitle {
    margin-top: 12px;
    font-weight: lighter;
    font-size: 20px;
    color: gray;
}