.upgrade-modal-img {
    position: relative;
    border-radius: 0 8px 8px 0;
}

.upgrade-modal-img::before {
    background: url(assets/icons/thunderbolt.svg) no-repeat;
    background-size: 250px;
    background-position: -60px;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
}

.upgrade-modal-content > .modal-content {
    background: none;
    border: none;
}

.upgrade-modal-backdrop {
    opacity: 0.8 !important;
}

.upgrade-card::before {
    background: url(assets/icons/thunderbolt.svg) no-repeat;
    background-size: 200px;
    background-position: 180px;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
}

.upgrade-card {
    position: relative;
    border-radius: 5px;
    width: 300px;
    height: 180px;
}

.upgrade-bg-purple {
    background: -moz-linear-gradient(200deg, rgb(220, 137, 232) 0%, rgb(144, 64, 204) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(200deg, rgba(220, 137, 232, 1) 0%, rgba(144, 64, 204, 1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(200deg, rgba(220, 137, 232, 1) 0%, rgba(144, 64, 204, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.upgrade-bg-green {
    background: -moz-linear-gradient(200deg, rgb(220, 137, 232) 0%, rgb(137, 232, 147) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(200deg, rgba(220, 137, 232, 1) 0%, rgba(137, 232, 147, 1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(200deg, rgba(220, 137, 232, 1) 0%, rgba(137, 232, 147, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.upgrade-bg-yellow {
    background: -moz-linear-gradient(200deg, rgb(220, 137, 232) 0%, rgb(232, 219, 137) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(200deg, rgba(220, 137, 232, 1) 0%, rgba(232, 219, 137, 1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(200deg, rgba(220, 137, 232, 1) 0%, rgba(232, 219, 137, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}