.container-login {
    max-width: 1200px;
    margin: auto;
    display: flex;
    padding-bottom: 3rem;
    padding-top: 3rem;
}

.container-login-p {
    color: white;
    font-size: 14px;
    text-align: left;
    margin: 20px 0 50px 0;
}

.container-right {
    flex: 2;
    padding: 20px 0 20px 60px;
}

.container-left {
    padding: 20px 60px 20px 0;
    flex: 1;
    border-right: solid rgba(255, 255, 255, 0.7) 1px;
}
.container-left-b {
    padding: 20px 20px 20px 0;
    flex: 1;
}

@media (min-width: 480px) {
    .container-left-b {
        padding: 20px 60px 20px 0;
        flex: 1;
        border-right: solid rgba(208, 208, 208, 0.7) 1px;
    }
}

.small-card {
    max-width: 280px;
    margin: 0 auto 30px auto;
    zoom: 0.9;
    -moz-transform: scale(0.9);
    flex: 1;
}

.large-card {
    max-width: 230px;
    flex: 1;
}

.login-message {
    margin: 20px 0 20px;
    text-align: center;
    color: #6b6b6b;
    font-size: 16px;
}

.container-cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%
}

.toc {
    opacity: 0.5;
    font-size: 13px;
    margin: 8px;
}

@media (min-width: 950px) {
    .container-cards {
        min-width: 585px;
    }
}

.login-box {
    min-width: 300px;
}

.learn-more {
    display: block;
    margin-left: 0;
    margin-right: auto;
}

@media (max-width: 750px) {
    .container-login {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 10%;
    }

    .container-left {
        padding: unset;
        border: unset;
    }

    .container-right {
        border-top: solid 1px rgba(255, 255, 255, 0.32);
        padding: 50px 0 0 0;
        margin-top: 50px;
    }

    .container-cards {
        display: flex;
        flex-direction: column;
    }

    .large-card {
        max-width: 330px;
        margin: 30px auto 0 auto;
    }
    .small-card {
        width: 280px;
    }

    .login-box {
        width: unset;
    }
}

.social-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    transition: all 0.2s ease-in-out;
    border-radius: 10px;
}

.social-button:hover {
    background: #f3f3f3;
}

.social-button img {
    width: 17px;
    height: 17px;
    margin-right: 5px;
}
