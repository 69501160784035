.container {
    display: flex;
    justify-content: center;
    gap: 10px;
}

@media (max-width: 500px) {
    .container {
        display: flex;
        flex-direction: column;
        box-shadow: none;
        align-items: center;
    }
}