.overflowMenu {
    padding: 0 7px 0 7px;
    display: inline-block;
    cursor: pointer;
    border-radius: 5px;
}

.overflowMenu:hover {
    background: #e9e9e9;
}

.container {
    border-radius: 0;
    border: none;
    background: transparent;
}

.popoverContainer{
    padding: 0;
    border-radius: 20px;
    box-shadow: 0 2px 20px 3px rgb(0 0 0 / 5%);
}