.container-pro-welcome {
    max-width: 700px;
    padding-top: 200px;
    margin-left: auto;
    margin-right: auto;
}

.pro-hello {
    margin-top: 300px;
}

.pro-hello-text {
    margin-top: 14px;
    font-size: 28px;
    font-weight: lighter;
}

.pro-welcome-header {
    display: flex;
    width: 100%;
    height: 40px;
    margin-bottom: 20px;
    font-size: 20px;
}

.pro-welcome-text {
    margin-top: 14px;
    font-size: 18px;
    font-weight: lighter;
}

.divider::before {
    border-left: solid grey 1px;
    content: " ";
    margin-right: 12px;
}