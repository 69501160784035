.border-button {
    font-size: 14px;
    border: solid 2px white;
    border-radius: 5px;
    color: white;
    background: none;
    padding: 3px 7px 3px 7px;
}

.border-button:hover {
    font-size: 14px;
    border: solid 2px white;
    border-radius: 5px;
    color: white;
    background: rgba(255, 255, 255, 0.19);
    padding: 3px 7px 3px 7px;
}
