.page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    gap: 64px;
}

.header {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 900px;
    padding: 10px;
}

.headerText {
    text-align: center;
    font-size: 64px;
    font-style: normal;
    font-weight: 900;
    line-height: 64px;
    color: #111827;
}

.colorText {
    background: linear-gradient(90deg, #AE5DD7 60%, #0D6EFD 90%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent
}

.content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 64px;
    padding: 16px;
}

.features {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
}

.feature {
    display: flex;
    padding: 24px;
    cursor: pointer;
    border: solid 1px transparent;
    min-width: 580px;
}

.feature:hover {
    border-radius: 24px;
    border: 1px solid #F6EEFB;
    background: linear-gradient(91deg, rgba(13, 110, 253, 0.01) 0%, rgba(174, 93, 215, 0.06) 100%), #FFF;
    transform: scale(1.05);
}

.featureIcon {
    width: 64px;
    height: 64px;
    background: #F3F4F6;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.featureText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 16px;
    text-align: left;
}

.title {
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    color: #6B7280;
}

.activeTitle {
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    color: #1F2937;
}

.description {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #606060;
    margin-top: 8px;
}

.transition {
    transform: translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    transition-duration: 0.2s;
    transition-property: transform;
}

.active {
    border-radius: 24px;
    border: 1px solid #F6EEFB;
    background: linear-gradient(91deg, rgba(13, 110, 253, 0.01) 0%, rgba(174, 93, 215, 0.06) 100%), #FFF;
    transform: scale(1.05);
}

.imgFill {
    filter: grayscale(0%) invert(24%) sepia(0%) saturate(0%) hue-rotate(230deg) brightness(95%) contrast(99%);
}

.iconActive {
    filter: grayscale(0%) invert(82%) sepia(21%) saturate(4178%) hue-rotate(221deg) brightness(96%) contrast(101%);
}

.featureImageCon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 24px;
    border: 1px solid var(--purple-050, #F6EEFB);
    background: radial-gradient(78.95% 80.36% at 21.07% 20.06%, rgba(13, 110, 253, 0.02) 0%, rgba(174, 93, 215, 0.06) 100%), #FFF;
}

@media (max-width: 950px) {
    .feature {
        min-width: initial;
    }

    .content {
        grid-template-columns: 1fr;
    }

    .featureImageCon {
        display: none;
    }

    .headerText {
        margin-top: 80px;
        text-align: center;
        font-size: 48px;
        font-style: normal;
        line-height: 48px;
        color: #111827;
    }

    .content {
        padding: 12px;
    }

    .active {
        margin: 0 8px
    }

    .title {
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        color: #6B7280;
    }

    .activeTitle {
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        color: #1F2937;
    }

    .description {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: #606060;
    }
}
