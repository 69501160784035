.container {
  flex: 1;
  background: white;
  padding: 20px;
  cursor: pointer;
  height: 100%;
  border-radius: 24px;
  fill: #fff;
  min-width: 275px;
  max-width: 300px;
  width: 100%;
  border: 1px solid #E5E7EB;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 4px 40px 0px #00000014;
  gap: 24px;
}

.planCard {
  padding: 32px 2px 2px;
}

.popularBg {
  background: #AE5DD7;
  border-radius: 24px;
}

.headerContainer h2 {
  text-align: left;
}

.subtitle {
  font-size: 16px;
  min-height: 48px;
}

.single {
  border-radius: 12px;
  width: 100% !important;
  border: 1px solid #f0eef1;
}

.container:hover {
  /* background: #fbf3fd; */
}

.dollar {
  font-size: 12px;
  margin-right: 4px;
  display: flex;
  justify-content: center;
  align-items: end;
  margin-top: 1rem;
  flex-wrap: wrap;

  p {
    font-size: 14px;
  }
}

.label {
  padding-left: 12px;
  padding-right: 12px;
  font-size: 14px;
}

.ctaButton {
  width: 100%;
}

.price {
  font-size: 32px;
  display: flex;
  justify-content: left;
  align-items: center;
}

.center {
  position: relative;
}

.end {
  border-right: solid 1px #f0eef1;
}

.disabled {
  opacity: 30%;
}

.popularBadge {
  font-size: 14px;
  padding-bottom: 4px;
  padding-top: 4px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -29px;
}

.period {
  font-weight: normal;
  font-size: 16px;
  margin-top: -7px;
}

.icon-blue {
  filter: invert(26%) sepia(84%) saturate(1748%) hue-rotate(199deg) brightness(91%) contrast(99%);
}

.icon-purple {
  filter: invert(54%) sepia(36%) saturate(6288%) hue-rotate(245deg) brightness(91%) contrast(83%);
}

.icon-warning {
  filter: invert(68%) sepia(32%) saturate(5424%) hue-rotate(13deg) brightness(96%) contrast(96%);
}

@media (max-width: 768px) {
  .container {
    padding: 36px;
    min-width: 100%;
  }

  .planCard {
    width: 100%;
    max-width: 100%;
  }

  .subtitle {
    font-size: 16px !important;
  }

  .dollar {
    img {
      width: 24px;
      height: 24px;
    }

    span {
      font-size: 16px;
    }
  }

  .single>.subtitle {
    font-size: 22px !important;
    min-height: 50px;
  }

  .start {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border-left: none;
  }

  .end {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-right: none;
  }

  .label {
    font-size: 16px;
  }
}